import React, { useEffect } from "react";
import { connect } from "react-redux";

const VolunteerForm = ({ language }) => {

  const formID = language === 'es' ? 'volunteers-needed-to-get-out-the-vote-2023' : 'volunteers-needed-to-get-out-the-vote-2023';

  useEffect(() => {
    if (language) {
      const scriptTag = document.getElementById('volunteer-scripts');
      const formTag = document.getElementById(`can-form-area-${formID}`);
      scriptTag.innerHTML = '';
      formTag.innerHTML = '';
      const script = document.createElement("script");
      script.src = `https://actionnetwork.org/widgets/v3/form/${formID}?format=js&source=widget`;
      script.async = true;
      document.getElementById('volunteer-scripts').appendChild(script);

      const style = document.createElement("link");
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = "https://actionnetwork.org/css/style-embed-whitelabel-v3.css";
      style.async = true;
      document.getElementById('volunteer-scripts').appendChild(style);
    }
  }, [language, formID]);

  return (
    <section id="volunteer-form" className="content slant quaternary content-support">
      <div className="content-inner">
        <div className="content-text">
          <h3>{language === 'es' ? "Sea Voluntario" : "Volunteer"}</h3>
          <div className="action-form" id={`can-form-area-${formID}`} />
          <div id="volunteer-scripts" />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = ({ language }) => {
  return { language }
};

export default connect(mapStateToProps)(VolunteerForm);