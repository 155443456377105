import React from "react";
import { connect } from "react-redux";

const Contribute = ({ language, styles }) => (
  <section
    id="contribute"
    className={`content slant quaternary content-support ${
      styles ? styles : ""
    }`}
  >
    <div className="content-inner">
      <div className="content-text">
        <h3>
          {language === "es" ? "Apoye nuestra causa" : "Support our cause"}
        </h3>
        {/* <iframe
          src="https://contribute.nycvotes.org/campaigns/pierinasanchez2/contributions/new?iframe=true"
          width="420px"
          height="985px"
          title="donate to my campaign"
        ></iframe> */}

        <a
          href="https://contribute.nycvotes.org/campaigns/pierinasanchez2/contributions/new"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-accent btn-point btn-lg"
        >
          Contribute to the campaign
        </a>
      </div>
    </div>
  </section>
);

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(Contribute);
