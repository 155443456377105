import React from "react";
import { connect } from "react-redux";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactBody from "react-body";
import VolunteerForm from "../components/volunteerForm";
import Contribute from "../components/contribute";
// import Events from "../components/events";

const VolunteerPage = ({ language }) => (
  <Layout id="volunteer">
    <SEO title="Volunteer" />
    <section className="content content-sub">
      <div className="content-inner">
        <div className="content-text">
          <h1>{ language === 'es' ? 
            'Sea Voluntario' 
            : 'Volunteer' }</h1>
          <div className="volunteer-content">
            <div className="volunteer-img">
              <div>
                <img src={require('../images/pi-headshot.png')} alt="Volunteer to help Pierina Sanchez" />
              </div>
            </div>
            <div className="volunteer-text">
              <h5>{language === 'es' ? 
                `Pierina Sánchez se postularse a la reelección para representar al Distrito 14 del Bronx en el Concejo de la Ciudad de Nueva York para luchar por un cambio progresivo en los sistemas que han mantenido el statu quo roto durante demasiado tiempo.`
                : `Pierina Sanchez is running for re-election to represent Bronx District 14 in the New York City Council to fight for progressive change to the systems that have maintained the broken status quo for too long.`}</h5>
              <h5>{language === 'es' ?
                `Pero para ganar, Pierina necesitará su ayuda para tocar puertas, hacer llamadas telefónicas y llegar a todos los votantes del distrito 14. ¡Regístrese para ser voluntario hoy y ayude a Pierina a ganar esta carrera!`
                : `But to win, Pierina will need your help to knock on doors, make phone calls and reach every voter in the 14th district. Sign up to be a volunteer today and help Pierina win this race!`}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <VolunteerForm />
    {/* <Events /> */}
    <Contribute styles="quaternary-dark" />
    <ReactBody className="volunteer" />
  </Layout>
);

const mapStateToProps = ({ language }) => {
  return { language }
};

export default connect(mapStateToProps)(VolunteerPage);
